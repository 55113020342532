

// export async function getData() {
//     setIsLoading(true);

//     try {
//     const response = await fetch('https://api.whatyouknow.com/v1/waitlist/');
//     const resData = await response.json();
    

//     // check for error
//     if(!response.ok){
//         throw new Error('Sign up process failed.')
//     }

//     // no errors so set resDAta
//     setAPIresponse(resData);
    
//     } catch (error) {
//             setAPIerror(error);
//     }
    
//     setIsLoading(false);
// };


export async function putData(postData) {

    const response = await fetch('https://api.whatyouknow.com/v1/waitlist/', {
        method: 'PUT',
        body: JSON.stringify(postData),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const resData = await response.json();
    
    // check for error
    if(!response.ok){
        throw new Error('Failed handling data.')
    }

    return resData;
}