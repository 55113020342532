import {useState, useRef } from "react";
import validator from 'validator';

import Box from "@mui/system/Box";
import { Button, TextField, Typography, Grid } from "@mui/material";
import { putData } from "./http";

import EmailBox from "./EmailBox";
import { useDispatch } from "react-redux";

function SignUpForm() {
  const [emailAddress, setEmailAddress] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  
  //  store
const dispatch = useDispatch();
const updateEmailHandler = () => {
  dispatch({type: 'update', payload: {value: emailAddress}});
}
// end store

  ///
//   const [APIresponse, setAPIresponse] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
  const [APIerror, setAPIerror] = useState('');
  
  const myForm = useRef();

  const handleEmailInput = function (event) {
   // console.log("inside...");
    let str = event.target.value;

    str = str.replace(" ", "");
    setEmailAddress(str.toLowerCase());
  };

 async function handleFormSubmit(event) {
  //  console.log("test");
  setIsError(false);
    event.preventDefault();

    if (emailAddress === "") {
        setIsError(true);
      alert("Please enter your email address");
      return;
    }

    if(!validator.isEmail(emailAddress)){
        setIsError(true);
        alert("Hmm... please doublecheck your email address. There may be a typo.");
        return; 
    }
    
    console.log("email: " + emailAddress);
    setIsDisabled((prev) => !prev);
    setIsSubmitted(true);

    updateEmailHandler();

    try {
    await putData({"emailAddress": emailAddress});
    } catch (error) {
        setAPIerror(error.message);
    }



  }

//   function handleCancel(event) {
//     event.preventDefault();
//     setEmailAddress("");
//   }

  function handleReset() {
    setEmailAddress("");
    setIsDisabled(false);
    setIsSubmitted(false);
  }

  ///
// useEffect( () => {
// async function fetchPost() {
//     setIsLoading(true);

//     try {
//     const response = await fetch('https://api.whatyouknow.com/v1/waitlist/');
//     const resData = await response.json();
    

//     // check for error
//     if(!response.ok){
//         throw new Error('Sign up process failed.')
//     }

//     // no errors so set resDAta
//     setAPIresponse(resData);
    
//     } catch (error) {
//             setAPIerror(error);
//     }
    
//     setIsLoading(false);
// }

// if(isSubmitted)
// fetchPost();

// }, [isSubmitted])



  ///
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>Join the waitlist:</div>
      <form
      ref={myForm}
                onSubmit={handleFormSubmit}
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Grid
          container
          sx={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            md={4}
            xs={10}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100vw",
                paddingTop: 1,
              }}
            > 
              <TextField
                fullWidth
                error={isError}
                // className="textField"
                disabled={isSubmitted}
                id="outlined-basic"
                label=""
                variant="outlined"
                value={emailAddress}
                onChange={(event) => handleEmailInput(event)}
                inputProps={{ "name": "emailAddress", "required": true, "type": "email"}}
                placeholder="Enter your email address"
                sx={{
                  input: { backgroundColor: "white", borderRadius: "5px" },
                  m: 1
                }}
              />

              <Box sx={{ paddingTop: 1 }}>
                {APIerror !== '' && JSON.stringify(APIerror)}
                {!isSubmitted && !APIerror && (
                  <>
                    <Button
                      disabled={isDisabled}
                      variant="contained"
                      size="medium"
                    onClick={(event) => handleFormSubmit(event)}
                      sx={{ marginBottom: 11 }}
                    >
                      Join the Waitlist
                    </Button>

                    {/* {isDisabled && <Button variant="contained"
                            disabled={isDisabled || emailAddress === ''}
                            onClick={handleCancel}
                            sx={{m: 1}}>Cancel</Button>} */}
                  </>
                )}
              </Box>
              {/* {isLoading && <div>LOADING!</div>} */}
              {isSubmitted && (
                <Box>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textTransform: "capitalize",
                      width: "100%",
                    }}
                  >
                    {/* <Typography>Success! data: {APIresponse}</Typography> */}
                    <Typography
                      sx={{ paddingY: 1, textTransform: "lowercase" }}
                    >
                      <b>{emailAddress}</b>
                    </Typography>
                    <Typography
                      sx={{ paddingY: 1, textTransform: "lowercase" }}
                    >
                      has been added to the waitlist.
                    </Typography>
                  </Button>

                  <Typography sx={{ paddingY: 1 }}>
                    You will receive email updates during our launch phase.
                  </Typography>
                  <Box>
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
      {/* <EmailBox /> */}
    </Box>
  );
}

export default SignUpForm;
