import { createStore } from "redux";

const emailAddressReducer = (state = { emailAddress: 'denhamturton@gmail.com' }, action) => {
  if (action.type === "update") {
    return {emailAddress: action.payload.value};
  }

  return state;
};

const store = createStore(emailAddressReducer);

export default store;

// store.dispatch(state, { type: "increment" });
