// import logo from './logo.svg';
import './App.css';

import Box from "@mui/system/Box";
import Header from './components/Header';
import Footer from './components/Footer';
import SignUpForm from './components/SignUpForm';
import { useEffect } from 'react';


function App() {

  useEffect(() => {
    document.title = 'WhoYouKnow - Helping You Network with Other People';
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Header />
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
        Help Your Network.
        </p>
      </header>
      <Box sx={{height: '40vh'}}>
      <SignUpForm />
        </Box>
      <Footer />
    </div>
  );
}

export default App;
